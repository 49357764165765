import React from 'react';
import './Footer.css';
import WowLogo from '../../media/img_wowlabz logo@3x.svg';
import Discord from '../../media/ic_discord@3x.svg';
import Instagram from '../../media/ic_instagram@3x.svg';
import Telegram from '../../media/ic_telegram@3x.svg';

function Footer() {
	return (
		<footer className='footer'>
			<p className='footer_text'>
				Powered by Wowlabz <img className='logo' src={WowLogo} alt='wowLogo' />
			</p>
			<div className='social_wrapper'>
				<img className='logo' src={Discord} alt='Discord' />
				<img className='logo' src={Telegram} alt='Telegram' />
				<img className='logo' src={Instagram} alt='Instagram' />
			</div>
		</footer>
	);
}
export default Footer;
