import React from 'react';
import { NavLink } from 'react-router-dom';
import icon from '../../media/icon.svg';
import './Opportunities.css';

function Opportunities({ opportunitiesData }) {

    // const navigate = useNavigate();

    // const deposit = () => {
    //     navigate('/vaults/asset', {state: data});
    // }

    // const deposit = () => {
    //     navigate('/vaults/asset');
    // }

    return (
        <div className="opportunities">
            <p className='opportunities-head'>Opportunities</p>
            <table className="opportunities-table">
                <tr className='opportunities-table-head'>
                    <th>Assets&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</th>
                    <th>APY&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</th>
                    <th>Total assets&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</th>
                    <th>Available to deposit</th>
                    <th></th>
                </tr>
                {/* {
                    opportunitiesData.map((data, index) => {
                        return(
                            <tr className='opportunities-table-row' key={index}>
                                <td className='opportunities-table-asset'><img src={icon} alt="icon" />&nbsp; &nbsp;{data.asset}</td>
                                <td>{data.apy}</td>
                                <td>{data.totalAssets}</td>
                                <td>{data.availableToDeposit}</td>
                                <NavLink to={{
                                    pathname: '/vaults/asset',
                                    aboutProps: {
                                        asset: data
                                    }
                                }}
                                exact
                                >
                                    <td><button className='opportunities-table-button'>Deposit</button></td>
                                </NavLink>
                            </tr>
                        )
                    })
                } */}
                <tr className='opportunities-table-row'>
                    <td className='opportunities-table-asset'><img className='opportunities-table-image' src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>15%</td>
                    <td>$532,234,449</td>
                    <td>0,04321</td>
                    <td>
                        <NavLink to={{
                            pathname: '/vaults/asset',
                            // aboutProps: {
                            //     state: data
                            // }
                        }}
                        exact
                        >
                            <button className='opportunities-table-button'>Deposit</button>
                        </NavLink>
                    </td>
                </tr>
                <br/>

                <tr className='opportunities-table-row'>
                    <td className='opportunities-table-asset'><img className='opportunities-table-image' src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>15%</td>
                    <td>$532,234,449</td>
                    <td>0,04321</td>
                    <td>
                        <NavLink to={{
                            pathname: '/vaults/asset',
                            // aboutProps: {
                            //     state: data
                            // }
                        }}
                            exact
                        >
                            <button className='opportunities-table-button'>Deposit</button>
                        </NavLink>
                    </td>
                </tr>
                <br />

                <tr className='opportunities-table-row'>
                    <td className='opportunities-table-asset'><img className='opportunities-table-image' src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>15%</td>
                    <td>$532,234,449</td>
                    <td>0,04321</td>
                    <td>
                        <NavLink to={{
                            pathname: '/vaults/asset',
                            // aboutProps: {
                            //     asset: data
                            // }
                        }}
                            exact
                        >
                            <button className='opportunities-table-button'>Deposit</button>
                        </NavLink>
                    </td>
                </tr>
                <br />

                <tr className='opportunities-table-row'>
                    <td className='opportunities-table-asset'><img className='opportunities-table-image' src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>15%</td>
                    <td>$532,234,449</td>
                    <td>0,04321</td>
                    <td>
                        <NavLink to={{
                            pathname: '/vaults/asset',
                            // aboutProps: {
                            //     state: data
                            // }
                        }}
                            exact
                        >
                            <button className='opportunities-table-button'>Deposit</button>
                        </NavLink>
                    </td>
                </tr>
                <br />

            </table>
        </div>
    )
}
export default Opportunities;