import {InjectedConnector} from '@web3-react/injected-connector';
import {WalletConnectConnector} from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import {BscConnector} from '@binance-chain/bsc-connector';
import {PortisConnector} from '@web3-react/portis-connector';
// import { PortisConnector } from '@web3-react/gnosis-safe';

// import { } from 'dotenv/config'
import { ethers } from 'ethers';
import contractabi from '../vault.json';

// const RPC_URLS = {
//     1: 'https://mainnet.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4',
//     4: 'https://rinkeby.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4'
// };

// const API_KEY = process.env.REACT_APP_API_KEY;
// const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY;
// const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42], });

// provider
const provider = new ethers.providers.Web3Provider(window.ethereum);
// // signer
const signer = provider.getSigner(0);
// const signer = wallet.connect(window.ethersProvider)
// contract
const contract = new ethers.Contract("0x6394edceA18775B84759AF814d9c95f859c03Ab8", contractabi, signer);

console.log("contract", contract)
const APY = contract.APY();
console.log("APY", APY);

const deposit = (amount) => {
    const tx = contract.deposit(amount)
    return tx;
}

const redeem = (amount) => {
    const tx = contract.redeem(amount)
    return tx;
}



// const walletConnect = new WalletConnectConnector({
//     rpcUrl: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
//     bridge: "https://bridge.walletconnect.org",
//     qrcode: true,
// })

// const walletConnect = new WalletConnectConnector({
//     rpc: {
//         1: RPC_URLS[1],
//         4: RPC_URLS[4]
//     },
//     qrcode: true,
//     pollingInterval: 15000
// });

// const portis = new PortisConnector({
//     dappId: process.env.PORTIS_ID,
// })

// const walletLink = new WalletLinkConnector({
//     url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
//     appName: "Web3-react Demo",
//     supportedChainIds: [1, 3, 4, 5, 42],
// });

// const walletlink = new WalletLinkConnector({
//     url: RPC_URLS[4],
//     appName: 'demo-app',
//     supportedChainIds: [1, 4]
// });

// const binanceConnect = new BscConnector({
//     url: RPC_URLS[4],
//     appName: 'demo-app',
//     supportedChainIds: [1, 4]
// });


export const connectors = {
    injected: injected,
    provider: provider,
    contract: contract,
    deposit: deposit,
    redeem: redeem,
    // walletConnect: walletConnect,
    // coinbaseWallet: walletlink,
    // binanceConnect: binanceConnect,
    // portis: portis,
}