import React, { useState } from 'react';
import TotalNetWorth from '../../components/content/TotalNetWorth';
import VaultEarnings from '../../components/content/VaultEarnings';
import VaultYield from '../../components/content/VaultYield';
import NftWallet from '../../components/walletConnection/NftWallet';
import TransactionOverview from '../../components/transaction/TransactionOverview';
import BackgroundGradient1 from '../../components/background/BackgroundGradient1';
import BackgroundGradient2 from '../../components/background/BackgroundGradient2';
import BackgroundGradient3 from '../../components/background/BackgroundGradient3';

import './Dashboard.css';

function Dashboard({
	walletConnection,
	totalNetWorth,
	vaultEarnings,
	vaultYield,
	transactionData,
	walletAddress,
}) {
	return (
		<div className='dashboard'>
			<BackgroundGradient1 />
			<BackgroundGradient2 />
			{/* <BackgroundGradient3 /> */}

			<div className='row dashboard_row'>
				<TotalNetWorth totalNetWorth={totalNetWorth} />
				<VaultEarnings vaultEarnings={vaultEarnings} />
				<VaultYield vaultYield={vaultYield} />
			</div>

			<div className='row wallet_row'>
				{!walletConnection ? (
					<NftWallet walletAddress={walletAddress} />
				) : (
					<TransactionOverview transactionData={transactionData} />
				)}
			</div>
		</div>
	);
}
export default Dashboard;
