import React from "react";
import curveIcon from '../../media/curveIcon.svg';
import curveLogo from '../../media/curveLogo.svg';
import './HighestAPY.css';

function HighestAPY(){
    return(
        <div className="highestAPY">
            <p className='highestAPY-head'>Highest APY</p>
            <table className="highestAPY-table">

                <tr className='highestAPY-table-row'>
                    
                    <td className='highestAPY-table-asset'>
                        <img className='highestAPY-table-image' src={curveIcon} alt="icon" />&nbsp; &nbsp;
                        <div>
                            <p className='highestAPY-table-row-text'>NEW</p>
                            <p>Curve Rocket Pool</p>
                        </div>
                    </td>
                    <td className='highestAPY-table-asset'>
                        <img className='highestAPY-table-image' src={curveLogo} alt="icon" />&nbsp; &nbsp;
                        <div>
                            <p className='highestAPY-table-row-text'>17.66%</p>
                            <p>Curve Rocket Pool</p>
                        </div>
                    </td>
                    <td className='highestAPY-table-asset'>
                        <img className='highestAPY-table-image' src={curveIcon} alt="icon" />&nbsp; &nbsp;
                        <div>
                            <p className='highestAPY-table-row-text'>14.92%</p>
                            <p>Curve Dola</p>
                        </div>
                    </td>
                </tr>

            </table>
        </div>
    )
}
export default HighestAPY;