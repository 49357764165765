import React from "react";
import backgroundGradient3 from '../../media/background-gradient2.svg';
import './BackgroundGradient3.css'

function BackgroundGradient3() {
    return (
        <div>
            <img className='backgroundGradient3' src={backgroundGradient3} alt='background' />
        </div>
    )
}
export default BackgroundGradient3;