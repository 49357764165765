import React from "react";
import backgroundGradient1 from '../../media/background-gradient2.svg';
import './BackgroundGradient1.css'

function BackgroundGradient1() {
    return(
        <div>
            <img className='backgroundGradient1' src={backgroundGradient1} alt='background' />
        </div>
    )
}
export default BackgroundGradient1;