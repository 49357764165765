import React, { useEffect, useState } from "react";
import assetImage from '../../media/assetImage.svg';
import downArrow from '../../media/down-arrow.svg';
import Submitted from "../modals/Submitted";
import Success from '../modals/Success';
import './AssetTransaction.css';

function AssetTransaction({ deposit, redeem }) {

    const [isBalance, setIsBalance] = useState(true);

    const [depositClass, setDepositClass] = useState(true);
    const [withdrawClass, setWithdrawClass] = useState(false);

    const [isDeposit, setIsDeposit] = useState(true);
    const [isWithdraw, setIsWithdraw] = useState(false);

    const [showDepositModal, setShowDepositModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);

    const [showDepositSuccess, setShowDepositSuccess] = useState(false);
    const [showWithdrawSuccess, setShowWithdrawSuccess] = useState(false);

    const [input1Value, setInput1Value] = useState(0);
    const [input2Value, setInput2Value] = useState(0);

    // useEffect(() => {
    //     if(depositTx){
    //         setShowDepositModal(!showDepositModal)
    //     }
    // }, [depositTx])

    // useEffect(() => {
    //     if (withdrawTx) {
    //         setShowWithdrawModal(!showWithdrawModal)
    //     }
    // }, [withdrawTx])

    const handleSubmit = (e) => {
        e.preventDefault();
        try{
            if (isDeposit) {
                deposit(input1Value).then((tx) => {
                    console.log("deposit tx", tx)
                    setShowDepositModal(true)
                    setTimeout(() => {
                        setShowDepositSuccess(true)
                    }, 5000);
                    setInput1Value('')
                })
            }
            else {
                redeem(input1Value).then((tx) => {
                    console.log("redeem tx", tx)
                    setShowWithdrawModal(true)
                    setTimeout(() => {
                        setShowWithdrawSuccess(true)
                    }, 5000);
                    setInput1Value('')
                })
            }
        }catch(e){
            console.log(e)
            setIsBalance(false)
        }
    }

    const handleIsDeposit = () => {
        setDepositClass(true)
        setWithdrawClass(false)
        setIsDeposit(true);
        setIsWithdraw(false);
    }

    const handleIsWithdraw = () => {
        setDepositClass(false)
        setWithdrawClass(true)
        setIsDeposit(false);
        setIsWithdraw(true);
    }

    return (
        <div>
            <form className='asset-transaction' onSubmit={handleSubmit}>
                <div className='asset-transaction-button'>
                    <button className={depositClass ? `asset-transaction-deposit-button-click` : `asset-transaction-deposit-button`} onClick={handleIsDeposit}>Deposit</button>
                    <button className={withdrawClass ? `asset-transaction-withdraw-button-click` : `asset-transaction-withdraw-button`} onClick={handleIsWithdraw}>Withdraw</button>
                </div>
                <div className='asset-transaction-wallet'>
                    <div className='asset-transaction-wallet-top'>
                        <p className='asset-transaction-wallet-top-text'>{isDeposit ? `From wallet` : `From vault`}</p>
                        <p className='asset-transaction-wallet-top-balance'>Balance: {input1Value} cvxCRV</p>
                    </div>
                    <div>
                        {/* <figure>
                            <img className='asset-transaction-image1' src={assetImage} alt="image" />
                            <figcaption>some text</figcaption>
                        </figure> */}
                        <img className='asset-transaction-image1' src={assetImage} alt="image" />
                        <p className='asset-transaction-wallet-input-text'>Curve cvxCRV</p>
                        <input className='asset-transaction-wallet-input' type='text' placeholder='0.00' onChange={(e) => setInput1Value(parseInt(e.target.value))} />
                    </div>
                    <div className='asset-transaction-wallet-bottom'>
                        <p className='asset-transaction-wallet-bottom-text'>= $ 0.00</p>
                        <button className='asset-transaction-wallet-bottom-btn'>MAX</button>
                    </div>
                </div>

                <img className='asset-transaction-arrow' src={downArrow} alt='image' />

                <div className='asset-transaction-vault'>
                    <div className='asset-transaction-wallet-top'>
                        <p className='asset-transaction-wallet-top-text'>{isDeposit ? `To vault` : `To wallet`}</p>
                        <p className='asset-transaction-wallet-top-balance'>Balance: 0.0000 cvxCRV</p>
                    </div>
                    <div>
                        {/* <figure>
                            <img className='asset-transaction-image1' src={assetImage} alt="image" />
                            <figcaption>some text</figcaption>
                        </figure> */}
                        <img className='asset-transaction-image1' src={assetImage} alt="image" />
                        <p className='asset-transaction-wallet-input-text'>Curve cvxCRV</p>
                        <input className='asset-transaction-wallet-input' type='text' placeholder='0.00' onChange={(e) => setInput2Value(parseInt(e.target.value))} />
                    </div>
                    <div className='asset-transaction-wallet-bottom'>
                        <p className='asset-transaction-wallet-bottom-text'>= $ 0.00</p>
                        <p className='asset-transaction-wallet-bottom-yield'>Yield = 30.1%</p>
                    </div>
                </div>
                
                <button type='submit' className='asset-transaction-approve'>{isDeposit ? `Depsoit` : `Withdraw`}</button>
                
                {
                    !isBalance &&
                    <button className='asset-transaction-balance'>
                        Insufficient Balance
                    </button>
                }
            </form>
            {
                showDepositModal ?
                    <>
                        <Submitted type='Deposit' open={true} />
                    </>
                    : null
            }
            {
                showDepositSuccess ?
                    <>
                        <Success type='deposit' to='vault' open={true} />
                    </>
                    : null
            }
            {
                showWithdrawModal ?
                    <>
                        <Submitted type='Withdraw' open={true} />
                    </>
                    : null
            }
            {
                showWithdrawSuccess ?
                    <>
                        <Success type='withdraw' to='wallet' open={true} />
                    </>
                    : null
            }
        </div>
    )
}
export default AssetTransaction;