import React, { useState, useEffect, useSWR } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import Vaults from './pages/vaults/Vaults';
import Asset from './pages/asset/Asset';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

import { connectors } from './utils/connectors';

import { ethers } from 'ethers';
// import contractabi from './vault.json';
// require('dotenv').config();

import { useWeb3React } from '@web3-react/core';
import './App.css';
import LandingPage from './pages/landingpage/LandingPage';

function App() {
	// const infuraKey = process.env.REACT_APP_INFURA_KEY;

	const { active, chainId, account, library } = useWeb3React();

	console.log('account', account);

	// const provider = new ethers.providers.Web3Provider(window.ethereum);
	// const contract = new ethers.Contract("0x4e5e3925781fa770087240898CA536013572fB8b", contractabi, provider);

	// console.log("contract", contract)
	// const APY = contract.APY();
	// console.log("APY", APY);

	const [walletConnection, setWalletConnection] = useState(false);
	const [walletAddress, setWalletAddress] = useState('');
	const [walletAmount, setWalletAmount] = useState(0);
	const [totalNetWorth, setTotalNetWorth] = useState(0);
	const [vaultEarnings, setVaultEarnings] = useState(0);
	const [vaultYield, setVaultYield] = useState(0);
	const [transactionData, setTransactionData] = useState([{}]);
	const [opportunitiesData, setOpportunitiesData] = useState([{}]);
	const [dashboardCSS, setDashboardCSS] = useState(false);
	const [vaultCSS, setVaultCSS] = useState(false);

	const balance = connectors.provider.getBalance(account).then((balance) => {
		console.log('balance', balance);
		setWalletAmount(ethers.utils.formatEther(balance));
	});

	useEffect(() => {
		if (account) {
			setWalletAddress(account);
			setWalletConnection(true);
		}
	}, [account]);

	// const deposit = (amount) => {
	//   contract.deposit(amount).then((tx) => {
	//     console.log("tx", tx);
	//   })
	// }

	// const redeem = (amount) => {
	//   contract.redeem(amount).then((tx) => {
	//     console.log("tx", tx);
	//   })
	// }

	return (
		<div className='fill-window'>
			<BrowserRouter>
				{/* <Navbar
          walletConnection={walletConnection}
          walletAddress={walletAddress}
          walletAmount={walletAmount}
        /> */}

				<Routes>
					{/* <Route path="/" element={<Navigate to='/dashboard' replace />}
          /> */}

					<Route
						path='/'
						element={
							<>
								<Navbar
									landingCSS={true}
									dashboardCSS={false}
									vaultCSS={false}
									walletConnection={walletConnection}
									walletAddress={walletAddress}
									walletAmount={walletAmount}
								/>
								<LandingPage
									walletConnection={walletConnection}
									totalNetWorth={totalNetWorth}
									vaultEarnings={vaultEarnings}
									vaultYield={vaultYield}
									transactionData={transactionData}
									walletAddress={walletAddress}
								/>
							</>
						}
					/>

					<Route
						path='/dashboard'
						element={
							<>
								<Navbar
									landingCSS={false}
									dashboardCSS={true}
									vaultCSS={false}
									walletConnection={walletConnection}
									walletAddress={walletAddress}
									walletAmount={walletAmount}
								/>
								<Dashboard
									walletConnection={walletConnection}
									totalNetWorth={totalNetWorth}
									vaultEarnings={vaultEarnings}
									vaultYield={vaultYield}
									transactionData={transactionData}
									walletAddress={walletAddress}
								/>
							</>
						}
					/>

					<Route
						path='/vaults'
						element={
							<>
								<Navbar
									landingCSS={false}
									dashboardCSS={false}
									vaultCSS={true}
									walletConnection={walletConnection}
									walletAddress={walletAddress}
									walletAmount={walletAmount}
								/>
								<Vaults
									walletConnection={walletConnection}
									totalNetWorth={totalNetWorth}
									vaultEarnings={vaultEarnings}
									vaultYield={vaultYield}
									opportunitiesData={opportunitiesData}
								/>
							</>
						}
					/>

					<Route
						path='/vaults/asset'
						element={
							<>
								<Navbar
									dashboardCSS={false}
									vaultCSS={true}
									walletConnection={walletConnection}
									walletAddress={walletAddress}
									walletAmount={walletAmount}
								/>
								<Asset
									walletConnection={walletConnection}
									walletAddress={walletAddress}
									deposit={connectors.deposit}
									redeem={connectors.redeem}
								/>
							</>
						}
					/>
				</Routes>

				<Footer />
			</BrowserRouter>
		</div>
	);
}

export default App;
