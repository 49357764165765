import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../media/logo.svg';
import settings from '../../media/settings.svg';
import ConnectWallet from '../modals/ConnectWallet';
import './Navbar.css';

function Navbar({
	landingCSS,
	dashboardCSS,
	vaultCSS,
	walletConnection,
	walletAddress,
	walletAmount,
}) {
	const [showModal, setShowModal] = useState(false);
	// const [dashboardCSS, setDashboardCSS] = useState(false);
	// const [vaultCSS, setVaultCSS] = useState(false);

	// const handleDashboard = () => {
	//     setDashboardCSS(true);
	//     setVaultCSS(false);
	// }

	// const handleVault = () => {
	//     setDashboardCSS(false);
	//     setVaultCSS(true);
	// }

	const handleShow = () => {
		setShowModal(true);
	};

	return (
		<>
			<nav className='navbar'>
				<ul className='navbar-list'>
					<NavLink to='/'>
						<li className='navbar-logo'>
							<img src={logo} alt='logo' />
						</li>
					</NavLink>
					<NavLink to='/'>
						<li className='navbar-logo-text'>WowFi</li>
					</NavLink>

					{!landingCSS && (
						<>
							<NavLink to='/dashboard'>
								<li className={dashboardCSS ? 'navbar-list-dashboard2' : 'navbar-list-dashboard1'}>
									Dashboard
								</li>
							</NavLink>
							<NavLink to='/vaults'>
								<li className={vaultCSS ? 'navbar-list-vault2' : 'navbar-list-vault1'}>Vaults</li>
							</NavLink>
						</>
					)}

					{!landingCSS ? (
						<div>
							{walletConnection ? (
								<div>
									<li>
										<button className='navbar-list-eth-button'>ETH</button>
									</li>
									<li>
										{/* <button className='navbar-list-amount-button'>72.40 ETH <button className='navbar-list-address-button'>0xBBB6...e969</button></button> */}
										<button className='navbar-list-amount-button'>
											{Number(walletAmount).toFixed(2)} ETH{' '}
											<button className='navbar-list-address-button'>
												{walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}
											</button>
										</button>
									</li>
								</div>
							) : (
								<div>
									<li>
										<button className='navbar-list-wallet-button' onClick={handleShow}>
											Connect to Wallet
										</button>
									</li>
								</div>
							)}
							<li className='navbar-list-settings'>
								<button className='navbar-list-settings-button'>
									<img src={settings} alt='settings' />
								</button>
							</li>
						</div>
					) : (
						<div>
							<li>
								<NavLink to='/dashboard'>
									<button className='navbar-list-wallet-button'>Launch App</button>
								</NavLink>
							</li>
						</div>
					)}
				</ul>
			</nav>
			{showModal ? <ConnectWallet walletAddress={walletAddress} /> : null}
		</>
	);
}
export default Navbar;
