import React, { useState } from "react";
import cross from '../../media/cross.svg';
import metamask from '../../media/metamask.svg';
import binance from '../../media/binance.svg';
import walletConnect from '../../media/walletConnect.svg';
import portis from '../../media/portis.svg';

import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../utils/connectors";

import './ConnectWallet.css';

function ConnectWallet({ walletAddress }){

    const [openModal, setOpenModal] = useState(true);
    const [check, setCheck] = useState(false);

    const web3reactContext = useWeb3React(); 

    const {activate, deactivate, active, account} = useWeb3React();

    // const connectCoinbase = async () => {
    //     try {
    //         await web3reactContext.activate(connectors.coinbaseWallet);
    //     } catch (ex) {
    //         console.log(ex);
    //     }
    // };

    // const connectWalletConnect = async () => {
    //     try {
    //         await web3reactContext.activate(connectors.walletConnect);
    //     } catch (ex) {
    //         console.log(ex);
    //     }
    // };

    // const connectBinance = async () => {
    //     try {
    //         await web3reactContext.activate(connectors.binanceConnect);
    //     } catch (ex) {
    //         console.log(ex);
    //     }
    // };

    const handleCheck = () => {
        setCheck(true);
    }

    const handleCross = () => {
        setOpenModal(false)
    }

    return(
        <>
        {
            !walletAddress && openModal &&
            <div className='connect-wallet'>
                <div>
                    <p className='connect-wallet-head'>Connect Wallet</p>
                    <img className='connect-wallet-cross' src={cross} alt="cross" onClick={handleCross} />
                </div>
                <div className='connect-wallet-wallets'>
                    <input className='connect-wallet-content-checkbox' type="checkbox" onClick={handleCheck} />
                    <p className='connect-wallet-content-text'>I have read, understood, and agree to the <a href="">Terms of Service</a></p>
                </div>
                <div className='connect-wallet-wallets' onClick={() => { check && activate(connectors.injected) }}>
                    <img className='connect-wallet-wallets-metamask' src={metamask} alt="metamask" />
                    <p>Meta Mask</p>
                </div>
                <div className='connect-wallet-wallets'>
                    <img className='connect-wallet-wallets-binance' src={binance} alt="binance" />
                    <p>Binance Chain Wallet</p>
                </div>
                <div className='connect-wallet-wallets'>
                    <img className='connect-wallet-wallets-walletConnect' src={walletConnect} alt="walletConnect" />
                    <p>WalletConnect</p>
                </div>
                <div className='connect-wallet-wallets'>
                    <img className='connect-wallet-wallets-portis' src={portis} alt="portis" />
                    <p>Portis</p>
                </div>

            </div>
        }
        </>    
    )
}
export default ConnectWallet;