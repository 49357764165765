import React, { useState } from 'react';
import TotalNetWorth from '../../components/content/TotalNetWorth';
import VaultEarnings from '../../components/content/VaultEarnings';
import VaultYield from '../../components/content/VaultYield';
import NftWallet from '../../components/walletConnection/NftWallet';
import DottedCircle from '../../media/img_circle dotted@3x.svg';
import PinkVector from '../../media/img_pink vector@3x.svg';
import DottedLine from '../../media/ic_dotted line@3x.svg';
import Zigzag from '../../media/ic_zigzag line@3x.svg';
import MobileAsset from '../../media/image2@3x.svg';
import WaveText from '../../media/1x/img_wave text.png';
import ImgArrow from '../../media/1x/Img_arrow.png';
import Mockup from '../../media/1x/img mockup.png';

import TransactionOverview from '../../components/transaction/TransactionOverview';
import BackgroundGradient1 from '../../components/background/BackgroundGradient1';
import BackgroundGradient2 from '../../components/background/BackgroundGradient2';
import BackgroundGradient3 from '../../components/background/BackgroundGradient3';

import './LandingPage.css';

function LandingPage({
	walletConnection,
	totalNetWorth,
	vaultEarnings,
	vaultYield,
	transactionData,
	walletAddress,
}) {
	return (
		<div className='dashboard'>
			{/* <BackgroundGradient1 /> */}
			{/* <BackgroundGradient2 /> */}
			{/* <BackgroundGradient3 /> */}
			<div className='row top'>
				<div className='landing_text'>
					<div className='row text-row'>
						<img className='dotted_circle' src={DottedCircle} alt='dotted_circle' />
						<h1 className='defi_title'>DeFi</h1>
					</div>
					<div className='vector_container'>
						<img className='pink_vector' src={PinkVector} alt='PinkVector' />

						<img className='dotted_line' src={DottedLine} alt='DottedLine' />
					</div>
					<div className='rem_title'>
						<h1>Reimagined.</h1>
					</div>

					<p>
						WowFi is a simplified lending, borrowing and <br />
						trading protocol built on the Ethereum blockchain.
					</p>
					<div className='row text-row'>
						<button className='explore_btn'>Explore Now</button>
						<p>{'Learn more >'}</p>
						<img className='zigzag_line' src={Zigzag} alt='Zigzag' />
					</div>
				</div>
				<div className='landing_asset'>
					<img className='mobile_asset' src={MobileAsset} alt='MobileAsset' />
				</div>
			</div>
			<div className='row wave_container'>
				<img className='wave_text' src={WaveText} alt='wave_text' />
			</div>
			<div className='row bottom'>
				<div className='landing_asset2'>
					<img className='mock_asset' src={Mockup} alt='Mockup' />
				</div>
				<div className='landing_text2'>
					<div className='text2_container'>
						<div className='row'>
							<h1>
								Put your crypto to work <img src={ImgArrow} alt='arrows' />
							</h1>
						</div>

						<p>
							WowFi is an Ethereum-based yield aggregation platform that helps users maximize yield
							by staking their cryptocurrency across various DeFi protocols.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
export default LandingPage;
