import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NftWallet from '../../components/walletConnection/NftWallet';
import BackButton from '../../components/buttons/BackButton';
import AssetOverview from '../../components/asset/AssetOverview';
import AssetTransaction from '../../components/asset/AssetTransaction'
import AssetPerformance from '../../components/asset/AssetPerformance';
import BackgroundGradient1 from '../../components/background/BackgroundGradient1';
import BackgroundGradient2 from '../../components/background/BackgroundGradient2';
import BackgroundGradient3 from '../../components/background/BackgroundGradient3';

import './Asset.css';

function Asset({ walletConnection, walletAddress, deposit, redeem }) {

    const [assetData, setAssetData] = useState({});
    // const location = useLocation();

    // useEffect(() => {
    //     setAssetData(location.aboutProps.asset);
    // }, [location])
    
    return (
        <div className='asset'>
            
            <BackgroundGradient1 />
            <BackgroundGradient2 />
            <BackgroundGradient3 />

            <BackButton path='/vaults'/>

            {
                !walletConnection ? <NftWallet walletAddress={walletAddress} /> :
                    <>
                        <AssetOverview assetData={assetData} />
                        <AssetTransaction
                            deposit={deposit}
                            redeem={redeem}
                        />
                        <AssetPerformance />
                    </>
            }
        </div>
    )
}
export default Asset;