import React, { useState, useEffect } from 'react';
import tick from '../../media/tick.svg';
import './Success.css';

function Success({ type, to, open }){
    const [openModal, setOpenModal] = useState(open);

    // useEffect(() => {
    //     setOpenModal(open);
    // }, [open])

    setTimeout(() => {
        setOpenModal(false)
    }, 5000);

    return(
        <>
        {
            openModal &&
                <div className='success'>
                    <div className='success-title'>
                        <img className='success-tick' src={tick} alt="tick" />
                        <p className='success-head'>&nbsp;Success</p>
                    </div>
                    <p className='success-text'>Transaction receipt {type} of <br /> 52CRVcvx to {to}</p>
                    <p className='success-link'>View on explorer</p>
                </div>
        }
        </>
    )
}
export default Success;
