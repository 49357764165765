import React from 'react';
import { NavLink } from 'react-router-dom';
import backArrow from '../../media/backArrow.svg';
import './BackButton.css';

function BackButton({path}) {
    return(
        <NavLink to={path} className="back-button">
            <img className='back-button-img' src={backArrow} alt="Back to previous page"/>
            <p className='back-button-text'>Back</p>
        </NavLink>
    )
}
export default BackButton;