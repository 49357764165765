import React, { useEffect, useState } from "react";
import depositSubmitted from '../../media/submitted.svg';
import './Submitted.css';

function Submitted({type, open}) {
    const [openModal, setOpenModal] = useState(open);

    // useEffect(() => {
    //     setOpenModal(open);
    // }, [open])

    const handleOpen = () => {
        setOpenModal(false)
    }

    return (
        <>
        {
            openModal && 
            <div className='submitted'>
                <img className='submitted-img' src={depositSubmitted} alt="submitted" />
                <p className='submitted-text'>{type} submitted</p>
                <button className='submitted-button' onClick={handleOpen} >Close</button>
            </div>
        }
        </>
    )
}
export default Submitted;