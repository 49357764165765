import React from 'react';
import './ValutEarnings.css';
import vaultEarningsImg from '../../media/vaultEarnings.svg';

function VaultEarnings({ vaultEarnings }) {
    return (
        <div className='vault-earnings'>
            <div className='vault-earnings-img'>
                <img src={vaultEarningsImg} alt='vaultEarnings' />
            </div>
            <div>
                <p className='vault-earnings-text'>Vaults earnings</p>
                <p className='vault-earnings-amount'>$ {vaultEarnings}</p>
            </div>
        </div>
    )
}
export default VaultEarnings;