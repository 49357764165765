import React from "react";
import './AssetInfo.css';

function AssetInfo({assetInfo}) {
    return (
        <div className='asset-info'>

            <div className='asset-info-statistics'>
                <table className='asset-info-statistics-table'>
                    <tr className='asset-info-statistics-table-row'>
                        <td>Pool APY</td>
                        <td className='asset-info-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetInfo.poolAPY} 0.81%</td>
                    </tr>
                    <tr className='asset-info-statistics-table-row'>
                        <td>Bonus rewards APR</td>
                        <td className='asset-info-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetInfo.bonusAPR} 0.00%</td>
                    </tr>
                    <tr className='asset-info-statistics-table-row'>
                        <td>Base CVR APR</td>
                        <td className='asset-info-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetInfo.baseAPR} 10.08%</td>
                    </tr>
                    <tr className='asset-info-statistics-table-row'>
                        <td>Boost</td>
                        <td className='asset-info-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetInfo.boost} 2.50%</td>
                    </tr>
                    <tr className='asset-info-statistics-table-row'>
                        <td>Convex APR</td>
                        <td className='asset-info-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetInfo.convexAPR} 30.61%</td>
                    </tr>
                    <tr className='asset-info-statistics-table-row'>
                        <td>Gross APR</td>
                        <td className='asset-info-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetInfo.grossAPR} 17.8%</td>
                    </tr>
                    <tr className='asset-info-statistics-table-row'>
                        <td>Net APY</td>
                        <td className='asset-info-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetInfo.netAPY} 17.5%</td>
                    </tr>
                </table>
            </div>
        </div>
    )
}
export default AssetInfo;