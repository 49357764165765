import React from 'react';
import upArrow from '../../media/upArrow.svg';
import icon from '../../media/icon.svg';
import './TransactionOverview.css';

function TransactionOverview({ transactionData }) {
    return(
        <div className="transaction-overview">
            <p className='transaction-overview-head'>Transaction Overview</p>
            <table className="transaction-overview-table">
                <tr className='transaction-overview-table-head'>
                    <th>Asset Name&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</th>
                    <th>Weekly growth</th>
                    <th>Invested</th>
                </tr>
                <br/>
                {/* {
                    transactionData.map((data, index) => {
                        return(
                            <tr className='transaction-overview-table-row' key={index}>
                                <td className='transaction-overview-table-asset'><img src={data.assetIcon} alt="icon" />&nbsp; &nbsp;{data.assetName}</td>
                                <td>{data.weeklyGrowth} <img src={upArrow} alt="up-arrow" /> {data.weeklyIncrease}</td>
                                <td>{data.invested}</td>
                            </tr>
                        )
                    })
                } */}
                <tr className='transaction-overview-table-row'>
                    <td className='transaction-overview-table-asset'><img src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>3.12% &nbsp;&nbsp;&nbsp;<img src={upArrow} alt="up-arrow" />&nbsp; 0.2</td>
                    <td>$2,340</td>
                </tr>
                <br/>
                <tr className='transaction-overview-table-row'>
                    <td className='transaction-overview-table-asset'><img src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>3.12% &nbsp;&nbsp;&nbsp;<img src={upArrow} alt="up-arrow" />&nbsp; 0.2</td>
                    <td>$2,340</td>
                </tr>
                <br/>
                <tr className='transaction-overview-table-row'>
                    <td className='transaction-overview-table-asset'><img src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>3.12% &nbsp;&nbsp;&nbsp;<img src={upArrow} alt="up-arrow" />&nbsp; 0.2</td>
                    <td>$2,340</td>
                </tr>
                <br/>
                <tr className='transaction-overview-table-row'>
                    <td className='transaction-overview-table-asset'><img src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>3.12% &nbsp;&nbsp;&nbsp;<img src={upArrow} alt="up-arrow" />&nbsp; 0.2</td>
                    <td>$2,340</td>
                </tr>
                <br/>
                <tr className='transaction-overview-table-row'>
                    <td className='transaction-overview-table-asset'><img src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>3.12% &nbsp;&nbsp;&nbsp;<img src={upArrow} alt="up-arrow" />&nbsp; 0.2</td>
                    <td>$2,340</td>
                </tr>
                <br />
                <tr className='transaction-overview-table-row'>
                    <td className='transaction-overview-table-asset'><img src={icon} alt="icon" />&nbsp; &nbsp;Bitcoin</td>
                    <td>3.12% &nbsp;&nbsp;&nbsp;<img src={upArrow} alt="up-arrow" />&nbsp; 0.2</td>
                    <td>$2,340</td>
                </tr>
                <br />

            </table>
        </div>
    )
}
export default TransactionOverview;