import React from 'react';
import TotalNetWorth from '../../components/content/TotalNetWorth';
import VaultEarnings from '../../components/content/VaultEarnings';
import VaultYield from '../../components/content/VaultYield';
import NftWallet from '../../components/walletConnection/NftWallet';
import HighestAPY from '../../components/highestAPY/HighestAPY';
import Opportunities from '../../components/opportunities/Opportunities';
import BackgroundGradient1 from '../../components/background/BackgroundGradient1';
import BackgroundGradient2 from '../../components/background/BackgroundGradient2';
import BackgroundGradient3 from '../../components/background/BackgroundGradient3';
import './Vaults.css';

function Vaults({
	walletConnection,
	walletAddress,
	walletAmount,
	totalNetWorth,
	vaultEarnings,
	vaultYield,
	opportunitiesData,
}) {
	return (
		<div className='vaults'>
			<BackgroundGradient1 />
			<BackgroundGradient2 />
			{/* <BackgroundGradient3 /> */}

			<div className='row dashboard_row'>
				<TotalNetWorth totalNetWorth={totalNetWorth} />
				<VaultEarnings vaultEarnings={vaultEarnings} />
				<VaultYield vaultYield={vaultYield} />
			</div>

			<div className='row wallet_row'>
				{!walletConnection ? (
					<NftWallet walletAddress={walletAddress} />
				) : (
					<div>
						<HighestAPY />
						<Opportunities opportunitiesData={opportunitiesData} />
					</div>
				)}
			</div>
		</div>
	);
}
export default Vaults;
