import React, { useEffect, useState } from "react";
import ConnectWallet from '../modals/ConnectWallet';
import nftWallet from '../../media/nftWallet.svg';
import { connectors } from "../../utils/connectors";
import './NftWallet.css';

function NftWallet({walletAddress}){
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => {
        setShowModal(true);
    }

    // useEffect(() => {
    //     if(connectors.provider)
    //         setShowModal(false);
    // }, [connectors.provider])

    return(
        <>
            {showModal ? <ConnectWallet walletAddress={walletAddress} /> :
                <div className='nft-wallet'>
                    <img src={nftWallet} alt="nft-wallet" onClick={handleShow} />
                    <p className='nft-wallet-text'>Connect to your wallet to start earning on Wowfi</p>
                </div>
            }
        </>
    )
    
}
export default NftWallet;