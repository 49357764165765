import React from "react";
import backgroundGradient2 from '../../media/background-gradient1.svg';
import './BackgroundGradient2.css'

function BackgroundGradient2() {
    return (
        <div>
            <img className='backgroundGradient2' src={backgroundGradient2} alt='background' />
        </div>
    )
}
export default BackgroundGradient2;