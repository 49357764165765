import React, { useState } from "react";
import assetImage from '../../media/assetImage.svg';
import info from '../../media/info.svg';
import AssetInfo from "../modals/AssetInfo";
import './AssetOverview.css';

function AssetOverview({ assetData }){

    const [assetInfo, setAssetInfo] = useState({});

    const [showModal, setShowModal] = useState(false);

    const handleShow = () => {
        setShowModal(!showModal);
    }

    return(
        <div className='asset-overview'>
            <p className='asset-overview-head'>Curve liquidity overview</p>

            <div className='asset-overview-statistics'>
                <div className='asset-overview-statistics-image'>
                    <img className='asset-overview-statistics-asset-image' src={assetImage} alt="image" />
                </div>
                <table className='asset-overview-statistics-table'>
                    <tr className='asset-overview-statistics-table-row'>
                        <td>APY</td>
                        <td className='asset-overview-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetData.APY} 17.37%&nbsp;<img className='asset-overview-statistics-table-row-info' src={info} alt="info" onClick={handleShow} />{showModal ? <AssetInfo assetInfo={assetInfo} /> : null}</td>
                    </tr>
                    <tr className='asset-overview-statistics-table-row'>
                        <td>Total assets</td>
                        <td className='asset-overview-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetData.totalAssets} $ 466,191</td>
                    </tr>
                    <tr className='asset-overview-statistics-table-row'>
                        <td>Type</td>
                        <td className='asset-overview-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetData.type} Curve LP Tokens</td>
                    </tr>
                    <tr className='asset-overview-statistics-table-row'>
                        <td>Website</td>
                        <td className='asset-overview-statistics-table-row-data'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{assetData.website} https://curve.fi/factory/22/</td>
                    </tr>
                </table>
            </div>

            <div className='asset-overview-about'>
                <p className='asset-overview-about-head'>About</p>
                <p className='asset-overview-about-text'>{assetData.about} Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            </div>

            <div className='asset-overview-strategies'>
                <p className='asset-overview-strategies-head'>Strategies</p>
                <p className='asset-overview-strategies-text'>{assetData.strategies} Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
        </div>
    )
}
export default AssetOverview;