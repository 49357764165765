import React from 'react';
import './TotalNetWorth.css';
import totalNetWorthImg from '../../media/totalNetWorth.svg';

function TotalNetWorth({ totalNetWorth }) {
    return(
        <div className='total-net-worth'>
            <div className='total-net-worth-img'>
                <img src={totalNetWorthImg} alt='totalNetWorth'/>
            </div>
            <div>
                <p className='total-net-worth-text'>Total net worth</p>
                <p className='total-net-worth-amount'>$ {totalNetWorth}</p>
            </div>
        </div>
    )
}
export default TotalNetWorth;