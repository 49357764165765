import React from "react";
import './AssetPerformance.css';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function AssetPerformance(){
    const data = {
        labels: ["Jan 29", "Jan 30", "Jan 31", "Feb 1", "Feb 2", "Feb 3", "Feb 4", "Feb 5", "Feb 6", "Feb 7", "Feb 8", "Feb 9", "Feb 10", "Feb 11", "Feb 12", "Feb 13", "Feb 14", "Feb 15", "Feb 16", "Feb 17", "Feb 18"],
        datasets: [
            {
                label: "Value",
                data: [33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65, 33, 53, 85],
                fill: true,
                backgroundColor: "#5CC6FF",
                radius: 8.5,
                borderColor: "#5CC6FF",
                borderWidth: 2,
            },
        ]
    };

    const options = {
        responsive: true,
        scales:{
            yAxis: {
                title: null,
                suffix: null,
                display: false,
                interval: 100,
                gridColor: 'transparent',
                gridLines: false,
                tickColor: 'transparent',
                labelFontColor: 'transparent',
                gridLines: {
                    display: false,

                },
                ticks: {
                    padding: 100
                }
            },
            xAxis: {
                title: null,
                prefix: null,
                interval: 1,
                display: false,
                gridColor: 'transparent',
                gridLines: false,
                lineColor: 'transparent',
                tickColor: 'transparent',
                labelFontColor: 'transparent',
                scaleBreaks: {
                    spacing: 10000,
                },
                gridLines: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    autoSkipPadding: 1000,
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
    }

    return (
        <div className='asset-performance'>
            <div className='asset-performance-title'>
                <div>
                    <p className='asset-performance-head'>Performance</p>
                    <p className='asset-performance-text'>Earnings over time</p>
                    <p className='asset-performance-balance'>5,661,30 crvCVX</p>
                </div>
                <div className='asset-performance-unit'>
                    <button className='asset-performance-unit-unit1'>crvCVX</button>
                    <button className='asset-performance-unit-unit2'>USD</button>
                </div>
            </div>

            <div className='asset-performance-timeline'>
                <div className='asset-performance-timeline-item1'>
                    <p className='asset-performance-timeline-item-date'>Jan 29</p>
                </div>
                <div className='asset-performance-timeline-item2'>
                    <p className='asset-performance-timeline-item-date'>Feb 01</p>
                </div>
                <div className='asset-performance-timeline-item1'>
                    <p className='asset-performance-timeline-item-date'>Feb 04</p>
                </div>
                <div className='asset-performance-timeline-item2'>
                    <p className='asset-performance-timeline-item-date'>Feb 07</p>
                </div>
                <div className='asset-performance-timeline-item1'>
                    <p className='asset-performance-timeline-item-date'>Feb 10</p>
                </div>
                <div className='asset-performance-timeline-item2'>
                    <p className='asset-performance-timeline-item-date'>Feb 13</p>
                </div>
                <div className='asset-performance-timeline-item1'>
                    <p className='asset-performance-timeline-item-date'>Feb 16</p>
                </div>

                <div className='asset-performance-timeline-chart'>
                    <Line data={data} options={options} height='50px' />
                </div>
                
            </div>

        </div>
    )
}
export default AssetPerformance;