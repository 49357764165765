import React from 'react';
import './VaultYield.css';
import vaultYieldImg from '../../media/vaultYield.svg';

function VaultYield({ vaultYield }) {
    return (
        <div className='vault-yield'>
            <div className='vault-yield-img'>
                <img src={vaultYieldImg} alt='vaultYield' />
            </div>
            <div>
                <p className='vault-yield-text'>Vaults est. yearly yield</p>
                <p className='vault-yield-amount'>$ {vaultYield}</p>
            </div>
        </div>
    )
}
export default VaultYield;